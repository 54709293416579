import React from 'react';
import Layout from 'components/Layout';

export default () => (
  <Layout tabTitlePrefix={'404 - Page not Found'}>
    <div>
      <h1>404</h1>
      <h2>Document not found</h2>
      <p>
        <a href="/">Return to homepage</a>
      </p>
    </div>
  </Layout>
);
